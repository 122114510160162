/* ---------------------- */
/* Main Container Spacing */
.eula {
    margin: 50px 0;
  }
  
  @media (max-width: 550px) {
    .eula {
      margin: 10px 15px;
    }
  }
  
  /* Heading Section */
  .eula .eula-heading {
    margin-bottom: 40px;
  }
  .eula .eula-heading h2 {
    text-align: left;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .eula .eula-heading p {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
  }
  
  @media (max-width: 550px) {
    .eula .eula-heading {
      margin-bottom: 30px;
    }
    .eula .eula-heading h2 {
      font-weight: 400;
      margin-bottom: 5px;
    }
    .eula .eula-heading p {
      font-size: 12px;
    }
  }
  
  /* Each Section */
  .eula .each-sec {
    text-align: left;
    margin-bottom: 70px;
    font-size: 18px; /* Adjust if desired for body text */
  }
  .eula .each-sec h2 {
    font-weight: 400;
    margin-bottom: 20px; /* or 30px, to match your preference */
  }
  
  @media (max-width: 550px) {
    .eula .each-sec {
      margin-bottom: 40px;
      font-size: 15px;
    }
    .eula .each-sec h2 {
      margin-bottom: 18px;
    }
  }
  