.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
}
.image-container {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text-container {
    align-self: stretch;
    max-width: 800px;
    margin: 0 auto;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

h3 {
    font-size: 28pt;
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 900;
}
p {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #454545;
    margin-bottom: 0;
}

.input-container {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    margin-bottom: 40px;
}
.email-input {
    outline: none;
    margin-top: 10px;
    border: 1px solid gray;
    padding: 10px 20px;
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
}

.button-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px
}

.submit-button {
    transition: 0.2s ease-in-out;
    background-color: rgba(176,35,165,1);
    color: white;
    transform: translateX(0);
    outline: none;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    font-size: 12pt;
    border-radius: 20px;
    padding: 5px 10px;
    margin-left: 15px;
}

.submit-button:hover {
    transform: translateY(-2px);
}

.logo {
    max-width: 100%;
    max-height: 100%;
}

.app-store-container {
    display: flex;
    justify-content: center;
}

.app-store-icon {
    width: 150px; /* Adjust size as needed */
    height: auto;
    cursor: pointer;
}

.policy-links {
    margin-top: 30px;
    text-align: center;
    
}

.policy-links a {
    text-decoration: none;
    color: #000; /* Bootstrap blue, change if needed */
    font-weight: 500;
}

.policy-links a:hover {
    color: #007bff; /* Change to blue on hover */
}



