.links-div p {
    font-size: 18px;
    font-weight: 500;
}

.privacy-terms-link {
    text-decoration: none;
    color: #000;
}

.main-links {
    display: flex;
    flex-direction: row;
    justify-content: center; /* ✅ center horizontally */
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 0;
  }
  